body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 767px) {
  html {
    overflow-x: hidden;
  }

  body {
    overflow: hidden;
  }
}

/* header */

header {
  width: 95%;
  max-width: 1600px;
  display: flex;
  min-height: 40px;
  margin: 0px auto;
}

.header__box {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr repeat(6, 1fr);
  grid-template-rows: auto;
  align-self: center;
  gap: 10px;
  margin-bottom: 10px;
}

.header__menu {
  font-size: 20px;
  font-weight: bold;
  text-align: end;
  align-self: center;
  padding: 10px;     
}

.header__logo {  
  display: inline-block;
  width: 400px;
  padding: 10px 0px;
}

@media screen and (max-width:500px) {
  .header__box {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
  }

  .header__logo {
    width: 300px;
  }

  .header__logo-text:nth-child(1) {
    grid-column: span 2;
  }

  .header__menu {
    text-align: center;
    background-color: #dadada;
    font-size: 15px;
  }
}

@media screen and (min-width:500px) and (max-width:1000px) {
  .header__box {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
  }

  .header__logo-text:nth-child(1) {
    grid-column: span 3;
  }

  .header__menu {
    text-align: center;
    background-color: #dadada;
    font-size: 15px;
  }
}

@media screen and (min-width:1000px) and (max-width:1500px) {
  .header__box {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, auto);
  }

  .header__logo-text:nth-child(1) {
    grid-column: span 6;
  }

  .header__menu {
    text-align: center;
    background-color: #dadada;
    font-size: 15px;
    margin-bottom: 15px;
  }
}

header a {
  text-decoration: none;
  color: #616161;
}

header a:focus,
header a:hover {
  color: black;
}

.profile__button {
  position: fixed;
  right: 2vh;
  top: 1vh;
  border-radius: 50%;
  border: 0;
  background-color: #2f2f2f;
  width: 40px;
  height: 40px;  
  font-weight: bold;
  color: white;
  cursor: pointer;
}

/* footer */

footer {
  background-color: #262626;
  min-height: 40px;
  margin: 0 auto;
}

.footer__box {
  margin: 0 auto;
  width: 95%;
  max-width: 1600px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
  padding: 30px 20px;
}

@media screen and (max-width:500px) {
  .footer__box {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width:850px) {
  .footer__box {
    grid-template-columns: repeat(3, 1fr);
  }
}

.footer__corp {
  grid-column-start: 1;
  grid-column-end: 3;
}

.footer__corp .footer__corp-name {
  font-size: 25px;
  font-weight: bold;
  color: white;
  padding-bottom: 15px;
}

.footer__corp-customer {
  padding: 0px, 5px;
}

.footer__customer-title {
  font-size: 17px;
  color: white;
  font-weight: bold;
}

.footer__customer-number {
  font-size: 38px;
  font-weight: bold;
  color: #009dff;
}

.footer__corp-item,
.footer__customer-item {
  font-size: 15px;
  color: #bfbfbf;
  padding: 5px;
}

.fixed__footer {
  position: fixed;
  display: grid;
  max-width: 80px;
  top: 45vh;
  right: 3vh;
  gap: 15px;
}

.fixed__footer-blog {  
  width: 100%;
  height: auto;
}

.fixed__footer-band {  
  width: 100%;
  height: auto;
}

.fixed__footer-youtube {  
  width: 100%;
  height: auto;
}

@media screen and (max-width:500px) {
  .fixed__footer {    
    max-width: 50px;
    top: 50vh;
    right: 1vh;
    gap: 10px;
  }
}

/* banner */
.banner__menu {
  position: relative;
  margin: 0 auto;
  width: 95%;
  height: auto;
  max-width: 1600px;
  overflow: hidden;
}

.banner__menu-bg {
  width: 100%;
  height: auto;
  object-fit: fill;
}

.banner__menu span {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  color: white;
  font-size: 45px;
  font-weight: 1000;
}

.banner__text-box {
  margin: 0 auto;
  font-size: 30px;
  font-weight: bold;
  width: 60%;
  color: #434343;
  margin-top: 40px;
  text-align: center;
}

.banner__text-small {
  font-size: 20px;
  font-weight: 500;
  color: #434343;
  margin-top: 20px;
}

@media screen and (max-width:700px) {
  .banner__menu span {
    font-size: 30px;
  }

  .banner__text-box {
    font-size: 20px;
  }

  .banner__text-small {
    font-size: 15px;
  }
}

/* carousel */
.carousel {
  margin: 0 auto;
  width: 95%;
  max-width: 1600px;
  aspect-ratio: 500 / 210;
}

@media screen and (max-width:850px) {
  .carousel {
    display: none;
  }
}

ul.carousel__slides {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.carousel__slides * {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

ul.carousel__slides input {
  display: none;
}

.carousel__slide-container {
  display: block;
}

.carousel__slide-img {
  display: block;
  position: absolute;
  top: 0;
  opacity: 0;
  transition: all 0.7s ease-in-out;
}

.carousel__slide-img img {
  width: 100%;
  height: auto;
}

.carousel__controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  font-size: 100px;
  margin-top: 12%;
  color: #fff;
}

.carousel__controls label {
  display: none;
  position: absolute;
  padding: 0 20px;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
}

.carousel__slide-img:hover+.carousel__controls label {
  opacity: 0.5;
}

.carousel__controls label:hover {
  opacity: 1;
}

.carousel__controls .carousel__slide-prev {
  width: 49%;
  text-align: left;
  left: 0;
}

.carousel__controls .carousel__slide-next {
  width: 49%;
  text-align: right;
  right: 0;
}

.carousel__dots {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 999;
  text-align: center;
}

.carousel__dots .carousel__dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.5;
  margin: 10px;
  margin-top: 40%;
}

input:checked+.carousel__slide-container .carousel__slide-img {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s ease-in-out;
}

input:checked+.carousel__slide-container .carousel__controls label {
  display: block;
}

input#img-1:checked~.carousel__dots label#img-dot-1,
input#img-2:checked~.carousel__dots label#img-dot-2,
input#img-3:checked~.carousel__dots label#img-dot-3,
input#img-4:checked~.carousel__dots label#img-dot-4 {
  opacity: 1;
}

input:checked+.carousel__slide-container .nav label {
  display: block;
}

/* form */
.form {
  margin: 0 auto;
  width: 95%;
  min-height: 980px;
  max-width: 1300px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 30px;
  background-color: #efefef;
}

.form__buy {
  min-height: 850px;
}

.form__roof {
  min-height: 800px;
}

.form input {
  height: 25px;
  padding: 10px 10px;
  font-size: 18px;
  border-radius: 0.3rem;
  border: 3px solid #d1d1d1;
  width: 95%;
  margin-bottom: 10px;
  text-indent: 20px;
  color: #707070;
}

.form input::placeholder {
  color: #c4c4c4;
  font-size: 16px;
}

.form .form__block {
  margin: 0 auto;
  margin-top: 40px;
  width: 33%;
  min-width: 300px;
}

.form__block-unit {
  position: relative;
  float: none;
}

.form__block-unit::after {
  position: absolute;
  content: 'KW';
  display: block;
  color: #707070;
  top: 40%;
  right: 16px;
  transform: translateY(-50%);
  font-size: 20px;
  font-weight: bold;
  pointer-events: none;
}

.form label {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 20px;
  color: #707070;
  font-size: 20px;
}

.form .form__btn--submit {
  width: 100%;
  height: 60px;
  padding: 10px;
  cursor: pointer;
  margin: 0 auto;
  font-size: 20px;
  font-weight: bold;
  background-color: #bd0000;
  color: white;
  border-radius: 30px;
  border: 0;
}

.form .form__btn--submit:hover,
.form .form__btn--submit:focus {
  background-color: #6b0101;
}

/* Login Form */

.form__login {
  margin: 0 auto;
  max-width: 680px;
  padding: 20px;
  margin-top: 20vh;
  height: 53vh;
}

.form__login input {
  height: 20px;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 0.3rem;
  border: 1px solid lightgray;
  width: 96%;
  max-width: 680px;
}

.form__block-login {
  margin-top: 20px;
  width: 100%;
}

.form__title {
  text-align: center;
  margin-bottom: 20px;
}

.form__block-login label {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 20px;
}

.form__block-login .form__btn--submit {
  width: 100%;
  height: 48px;
  font-weight: 600;
  padding: 10px 10px;
  float: right;
  cursor: pointer;
  margin: 0 auto;
  font-size: 16px;
  background-color: #2563eb;
  color: white;
  margin-top: 30px;
}

.form__block-login .form__btn--submit:hover,
.form__block-login .form__btn--submit:focus {
  background-color: #1945a4;
}

.form__error {
  margin-top: 20px;
  color: #bd0000;
}

/* Faq Form */
.form__faq {
  margin: 0 auto;
  max-width: 680px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 100px;
}

.form__faq input {
  height: 20px;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 0.3rem;
  border: 1.5px solid #c5c5c5;
  width: 96%;
  max-width: 680px;
}

.form__faq textarea {
  min-height: 300px;
  padding: 10px 10px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 0.3rem;
  border: 1.5px solid #c5c5c5;
  width: 96%;
  max-width: 680px;
}

.form__faq .form__block {
  margin-top: 20px;
  width: 100%;
}

.form__faq label {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 20px;
}

.form__faq .form__btn--submit {
  width: 100%;
  height: 48px;
  padding: 10px 10px;
  float: right;
  cursor: pointer;
  margin: 0 auto;
  color: white;
  border: 0;
  background-color: #2563eb;
}

.form__faq .form__btn--submit:hover,
.form__faq .form__btn--submit:focus {
  background-color: #1e4fba;
}

/* Sales New */

.form__new {
  margin: 0 auto;
  width: 95%;
  max-width: 1300px;
}

.form__new input::placeholder {
  color: #c4c4c4;
  font-size: 16px;
}

.sales__table-input {
  font-size: 15px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  width: 65%;
  height: 58px;
  padding-left: 15px;
}

.important__box {
  margin-top: 5px;
  margin-left: 12%;
  margin-right: 12%;
  line-height: 30px;
  font-size: 26px;
  font-weight: bold;
}

.form__btn--submit {
  margin: 60px 10px;
  padding: 15px;
  width: 220px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 30px;
  background-color: #bd0000;
  cursor: pointer;
  border: 0;
}

.form__btn--submit:hover,
.form__btn--submit:focus {
  background-color: #6b0101;
}

/* sale list */

.sales__list {
  width: 95%;
  max-width: 1300px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media screen and (max-width:500px) {
  .sales__list {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width:850px) {
  .sales__list {
    grid-template-columns: repeat(3, 1fr);
  }
}

.sales__list a {
  text-decoration: none;
}

.sales__new-button {
  position: fixed;
  right: 5vh;
  bottom: 15vh;
  border-radius: 50%;
  border: 0;
  background-color: #2563eb;
  width: 5vh;
  height: 5vh;
  font-size: 4vh;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.sales__new-button:hover,
.sales__new-button:focus {
  background-color: #163881;
}

.sales__completed {
  position: absolute;
}

.sales__item-photo {  
  width: 100%;
  height: auto;
  border-radius: 5%;
  aspect-ratio: 340 / 240;
}

.sales__address {
  color: black;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}

.sales__line {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid #f2f2f2;
}

.sales__info {
  display: flex;
}

.sales__price-box {
  color: #434343;
  font-size: 13px;
  width: 50%;
}

.sales__price {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 20px;
  color: #bd0000;
}

.sales__kw {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 20px;
  color: black;
}

.sales__kw-box {
  font-size: 13px;
  color: #434343;
  width: 50%;
}

/* sales detail */

.sales__detail {
  width: 95%;
  max-width: 1300px;
  margin: 0 auto;
  overflow: hidden;
}

.sales__util-box {
  display: flex;
  gap: 8px;
  flex-direction: row-reverse;
  font-size: 14px;
  color: gray;
  margin-bottom: 20px;
}

.sales__delete {
  cursor: pointer;
}

.sales__delete:hover,
.sales__delete:focus {
  color: black;
}

.sales__edit:hover,
.sales__edit:focus,
.sales__edit a:hover,
.sales__edit a:focus {
  color: black;
}

.sales__edit a {
  text-decoration: none;
  color: gray;
}

.sales__detail-title {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  margin-top: 60px;
  margin-bottom: 60px;
}

.sales__detail-summary {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 15px;
}


@media screen and (max-width:500px) {
  .sales__detail-summary {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width:850px) {
  .sales__detail-summary {
    grid-template-columns: repeat(5, 1fr);
  }
}

.sales__summary-box {
  border: 1px solid #e5e5e5;
  border-radius: 10%;
  padding-top: 12%;
  padding-bottom: 12%;
}

.sales__red {
  background-color: #ffeff4;
}

.sales__summary-tab {
  color: #434343;
  font-size: 15px;
  margin-left: 12%;
  margin-right: 12%;
}

.sales__summary-pricevalue {
  color: #bd0000;
}

.sales__summary-value,
.sales__summary-pricevalue {
  margin-top: 5px;
  margin-left: 12%;
  margin-right: 12%;
  line-height: 30px;
  font-size: 26px;
  font-weight: bold;
}

.sales__table {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin-top: 60px;
}

@media screen and (max-width:500px) {
  .sales__table {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width:850px) {
  .sales__table {
    grid-template-columns: repeat(2, 1fr);
    border: 2px solid #e5e5e5;
  }
}

.sales__table-row {
  display: flex;
}

.sales__table-tab {
  font-size: 15px;
  color: #737373;
  border: 1px solid #e5e5e5;
  height: 60px;
  line-height: 60px;
  padding-left: 15px;
  width: 35%;
  background-color: #fafafa;
}

.sales__table-value {
  font-size: 15px;
  color: #737373;
  border: 1px solid #e5e5e5;
  height: 60px;
  line-height: 60px;
  padding-left: 15px;
  width: 65%;  
}

.sales__detail-subtitle {
  margin-top: 60px;
  margin-bottom: 60px;
  font-size: 30px;
}

.sales__detail-phototable {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin-top: 60px;
  gap: 20px;
}

@media screen and (max-width:500px) {
  .sales__detail-phototable {
    grid-template-columns: 1fr;
  }

    .sales__summary-value,
    .sales__summary-pricevalue {
      margin-top: 5px;
      margin-left: 12%;
      margin-right: 12%;
      line-height: 30px;
      font-size: 20px;
      font-weight: bold;
    }
  
  .sales__table-tab {
    font-size: 13px;    
    padding-left: 7px;
    height: 40px;    
    line-height: 40px;
  }

  .sales__table-value {
    font-size: 13px;
    padding-left: 7px;
    height: 40px;
    line-height: 40px;
  }
}

@media screen and (min-width:850px) {
  .sales__detail-phototable {
    grid-template-columns: repeat(2, 1fr);
  }
}

.sales__detail-photo {
  width: 100%;
  height: auto;
  aspect-ratio: 340 / 240;  
}

.sales__detail-button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sales__detail-backlist {
  margin: 60px;
  padding: 15px;
  width: 220px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 30px;
  background-color: #bd0000;
  text-decoration: none;
  border: 0;
}

.sales__detail-backlist:hover,
.sales__detail-backlist:focus {
  background-color: #6b0101;
}

/* loader */

.loader {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 5px solid #2563eb;
  border-radius: 50%;
  z-index: 9999;
  animation: 1s linear infinite;
  animation: rotation 1s linear infinite;
}

/* profile */

.profile__box {
  display: flex;
  gap: 18px;
  align-items: center;
  font-size: 18px;
  margin: 0 auto;
  max-width: 1300px;
  text-align: left;
  line-height: 24px;
  justify-content: space-between;
  padding: 20px;
}

.flex__box-lg {
  display: flex;
  gap: 18px;
  align-items: center;
}

.profile__image {
  width: 72px;
  height: 72px;
  background-color: #b8b8b8;
  border-radius: 50%;
}

.profile__name {
  font-size: 16px;
  padding-top: 4px;
}

.profile__email {
  font-weight: 500;
}

.profile__logout {
  color: gray;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}

.profile__logout:hover,
.profile__logout:focus {
  color: black;
}

/* Faq List */
.faq__list {
  width: 95%;
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 30px;
  min-height: 650px;
  text-align: center;
  font-size: 14px;
  color: #616161;
  border-top: 1.5px solid #848484;
}

.faq__box {
  display: flex;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid #acacac;
}

.faq__box a {
  text-decoration: none;
}

.faq__index,
.faq__index-title {
  width: 10%;
  /* background-color: #1945a4; */
}

.faq__index-title {
  font-weight: bold;
}

.faq__list .faq__title,
.faq__list .faq__title-title {
  width: 60%;
  text-align: left;
  /* background-color: #707070; */
  color: #616161;
}

.faq__title-title {
  text-align: center;
  font-weight: bold;
}

.faq__list .faq__author-name,
.faq__list .faq__author-name-title {
  width: 15%;
  /* background-color: aquamarine; */
}

.faq__author-name-title {
  text-align: center;
  font-weight: bold;
}

.faq__list .faq__date,
.faq__list .faq__date-title {
  width: 15%;
  /* background-color: blueviolet; */
}

.faq__date-title {
  text-align: center;
  font-weight: bold;
}

.faq__no-faq {
  padding: 24px;
  text-align: center;
  color: gray;
  border-bottom: 1.5px solid #d2d2d2;
  font-size: 14px;
}

.faq__new-button {
  width: 80px;
  height: 35px;
  background-color: #bd0000;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0.3rem;
  border: 0;
  margin-top: 20px;
  cursor: pointer;
  float: right;
}

.faq__new-button:hover,
.faq__new-button:focus {
  background-color: #6b0101;
}

/* Faq Detail */

.faq__detail {
  width: 95%;
  min-height: 630px;
  padding: 20px;
  text-align: center;
  max-width: 1300px;
  margin: 0 auto;
  text-align: left;
  line-height: 24px;
}

.faq__detail-box {
  padding-top: 10px;
  padding-bottom: 24px;
  border-top: 2px solid #bebebe;
}

.faq__profile-box {
  display: flex;
  gap: 8px;
  font-size: 14px;
  align-items: center;
  padding: 10px 0px;
}

.faq__profile {
  width: 36px;
  height: 36px;
  background-color: #e0dfdf;
  border-radius: 50%;
}

.faq__date,
.faq__author-name {
  color: gray;
}

.faq__detail .faq__title {
  font-size: 30px;
  font-weight: 600;
  margin: 14px 0px;
  line-height: 40px;
}

.faq__text {
  padding: 20px 0px;
  color: dimgray;
  font-size: 16px;
}

.faq__text--pre-wrap {
  white-space: pre-wrap;
  border-top: 1px solid #d5d5d5;
}

.faq__list a {
  text-decoration: none;
  color: black;
}

.faq__utils-box {
  display: flex;
  gap: 8px;
  flex-direction: row-reverse;
  font-size: 14px;
  color: gray;
  padding: 10px 0px;
  border-top: 1px solid #d5d5d5;
}

.faq__delete {
  cursor: pointer;
}

.faq__delete:hover,
.faq__delete:focus {
  color: black;
}

.faq__edit:hover,
.faq__edit:focus,
.faq__edit:hover a:hover,
.faq__edit:hover a:focus {
  color: black;
}

.faq__edit a {
  text-decoration: none;
  color: gray;
}

/* Request */
.request__list {
  width: 95%;
  max-width: 1300px;
  margin: 0 auto;
  min-height: 770px;
  text-align: center;
  font-size: 14px;
  color: #616161;
  border-top: 1.5px solid #848484;
  padding-bottom: 10px;
}

.request__list p {
  font-weight: bold;
  font-size: 15px;
  text-align: left;
  padding-top: 10px;
}

.request__sell-box,
.request__buy-box,
.request__roof-box {
  display: flex;
  height: 40px;
  align-items: center;
  border-bottom: 1px solid #acacac;
}

.request__sell-list,
.request__buy-list,
.request__roof-list {
  display: flex;
  height: 30px;
  align-items: center;
  border-bottom: 1px solid #acacac;
}

.request__index,
.request__index-title,
.request__name,
.request__name-title,
.request__contact,
.request__contact-title,
.request__capacity,
.request__capacity-title,
.request__price,
.request__price-title {
  width: 10%;
}

.request__index-title,
.request__name-title,
.request__contact-title,
.request__capacity-title,
.request__address-title,
.request__price-title {
  font-weight: bold;
}

.request__address,
.request__address-title {
  width: 45%;
}

.request__roof-list .request__address,
.request__roof-box .request__address-title {
  width: 65%;
}

.request__contact,
.request__contact-title {
  width: 15%;
}

.request__no-request {
  padding: 5px;
  text-align: center;
  color: gray;
  border-bottom: 1.5px solid #d2d2d2;
  font-size: 14px;
}

/* Address */
.address__postcode {
  display: flex;      
  gap: 10px;
}

.address__postcode .postcode__button {
  width: 40%;
  height: auto;
  font-size: 15px;
  font-weight: bold;
  border-radius: 0.3rem;
  border: 3px solid #d1d1d1;  
  color: white;  
  text-indent: 0px;
  background-color: #022a7b;
}